import { NoteTakerOffer } from "@/interfaces/api";
import BaseService from "@/services/base-service";

class OfferService extends BaseService {
  /**
   * Fetches the offer with the specified id
   *
   * @param {number} offerId - The id of the note-taker offer
   * @returns {Promise<NoteTakerOffer | undefined>} A Promise with the NoteTakerOffer
   */
  getOfferById(offerId: number): Promise<NoteTakerOffer | undefined> {
    const endpointController = "Offer";
    const parameterName = "offerId";
    const parameterArgument = offerId;
    const getOfferById = `${this.baseApiUrl}api/${endpointController}?${parameterName}=${parameterArgument}`;
    return this.fetch<NoteTakerOffer>(getOfferById);
  }

  acceptOffer(offer: NoteTakerOffer): Promise<boolean> {
    const endpointController = "Offer";
    const acceptOffer = `${this.baseApiUrl}api/${endpointController}`;
    return this.fetch<boolean>(acceptOffer, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(offer),
    });
  }

  declineOffer(offerId: number): Promise<boolean> {
    const endpointController = "Offer";
    const parameterName = "offerId";
    const parameterArgument = offerId;
    const acceptOffer = `${this.baseApiUrl}api/${endpointController}?${parameterName}=${parameterArgument}`;
    return this.fetch<boolean>(acceptOffer, {
      method: "DELETE",
    });
  }
}

export default new OfferService();
